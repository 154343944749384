<template>
 <div></div>
</template>

<script>
export default {

    components: {

    },

    data: () => ({
      

    }),

    computed: {
    },
      

    watch: {

    },
    methods: {
      

    },
    created() {
        

    },
};
</script>
